@import '../../style/themes/default';
@import '../../style/mixins/index';

@backtop-prefix-cls: ~'@{ant-prefix}-back-top';

.@{backtop-prefix-cls} {
  .reset-component;
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: @zindex-back-top;
  width: 40px;
  height: 40px;
  cursor: pointer;

  &-content {
    width: 40px;
    height: 40px;
    overflow: hidden;
    color: @back-top-color;
    text-align: center;
    background-color: @back-top-bg;
    border-radius: 20px;
    transition: all 0.3s @ease-in-out;

    &:hover {
      background-color: @back-top-hover-bg;
      transition: all 0.3s @ease-in-out;
    }
  }

  &-icon {
    width: 14px;
    height: 16px;
    margin: 12px auto;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAAABGdBTUEAALGPC/xhBQAAAbtJREFUWAntmMtKw0AUhhMvS5cuxILgQlRUpIggIoKIIoigG1eC+AA+jo+i6FIXBfeuXIgoeKVeitVWJX5HWhhDksnUpp3FDPyZk3Nm5nycmZKkXhAEOXSA3lG7muTeRzmfy6HneUvIhnYkQK+Q9NhAA0Opg0vBEhjBKHiyb8iGMyQMOYuK41BcBSypAL+MYXSKjtFAW7EAGEO3qN4uMQbbAkXiSfRQJ1H6a+yhlkKRcAoVFYiweYNjtCVQJJpBz2GCiPt7fBOZQpFgDpUikse5HgnkM4Fi4QX0Fpc5wf9EbLqpUCy4jMoJSXWhFwbMNgWKhVbRhy5jirhs9fy/oFhgHVVTJEs7RLZ8sSEoJm6iz7SZDMbJ+/OKERQTttCXQRLToRUmrKWCYuA2+jbN0MB4OQobYShfdTCgn/sL1K36M7TLrN3n+758aPy2rrpR6+/od5E8tf/A1uLS9aId5T7J3CNYihkQ4D9PiMdMC7mp4rjB9kjFjZp8BlnVHJBuO1yFXIV0FdDF3RlyFdJVQBdv5AxVdIsq8apiZ2PyYO1EVykesGfZEESsCkweyR8MUW+V8uJ1gkYipmpdP1pm2aJVPEGzAAAAAElFTkSuQmCC)
      ~'100%/100%' no-repeat;
  }
}

@import './responsive';
