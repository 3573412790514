@import '../../style/themes/default';
@import '../../style/mixins/index';

@slider-prefix-cls: ~'@{ant-prefix}-slider';

.@{slider-prefix-cls} {
  .reset-component;
  position: relative;
  height: 12px;
  margin: @slider-margin;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;

  .vertical();

  &-with-marks {
    margin-bottom: 28px;
  }

  &-rail {
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: @slider-rail-background-color;
    border-radius: 2px;
    transition: background-color 0.3s;
  }

  &-track {
    position: absolute;
    height: 4px;
    background-color: @slider-track-background-color;
    border-radius: @border-radius-base;
    transition: background-color 0.3s ease;
  }

  &-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-top: -5px;
    margin-left: -7px;
    background-color: @component-background;
    border: solid 2px @slider-handle-color;
    border-radius: 50%;
    box-shadow: 0;
    cursor: pointer;
    transition: border-color 0.3s, box-shadow 0.6s,
      transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);

    &:focus {
      border-color: @slider-handle-color-focus;
      outline: none;
      box-shadow: 0 0 0 5px @slider-handle-color-focus-shadow;
    }

    &.@{ant-prefix}-tooltip-open {
      border-color: @slider-handle-color-tooltip-open;
    }
  }

  &:hover {
    .@{slider-prefix-cls}-rail {
      background-color: @slider-rail-background-color-hover;
    }
    .@{slider-prefix-cls}-track {
      background-color: @slider-track-background-color-hover;
    }
    .@{slider-prefix-cls}-handle:not(.@{ant-prefix}-tooltip-open) {
      border-color: @slider-handle-color-hover;
    }
  }

  &-mark {
    position: absolute;
    top: 14px;
    left: 0;
    width: 100%;
    font-size: @font-size-base;
  }

  &-mark-text {
    position: absolute;
    display: inline-block;
    color: @text-color-secondary;
    text-align: center;
    cursor: pointer;

    &-active {
      color: @text-color;
    }
  }

  &-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
  }

  &-dot {
    position: absolute;
    top: -2px;
    width: 8px;
    height: 8px;
    margin-left: -4px;
    background-color: @component-background;
    border: 2px solid @slider-dot-border-color;
    border-radius: 50%;
    cursor: pointer;
    &:first-child {
      margin-left: -4px;
    }
    &:last-child {
      margin-left: -4px;
    }
    &-active {
      border-color: @slider-dot-border-color-active;
    }
  }

  &-disabled {
    cursor: not-allowed;

    .@{slider-prefix-cls}-track {
      background-color: @slider-disabled-color !important;
    }

    .@{slider-prefix-cls}-handle,
    .@{slider-prefix-cls}-dot {
      background-color: @component-background;
      border-color: @slider-disabled-color !important;
      box-shadow: none;
      cursor: not-allowed;
    }

    .@{slider-prefix-cls}-mark-text,
    .@{slider-prefix-cls}-dot {
      cursor: not-allowed !important;
    }
  }
}

.vertical() {
  &-vertical {
    width: 12px;
    height: 100%;
    margin: 6px 10px;
    padding: 0 4px;

    .@{slider-prefix-cls}-rail {
      width: 4px;
      height: 100%;
    }

    .@{slider-prefix-cls}-track {
      width: 4px;
    }

    .@{slider-prefix-cls}-handle {
      margin-bottom: -7px;
      margin-left: -5px;
    }

    .@{slider-prefix-cls}-mark {
      top: 0;
      left: 12px;
      width: 18px;
      height: 100%;
    }

    .@{slider-prefix-cls}-mark-text {
      left: 4px;
      white-space: nowrap;
    }

    .@{slider-prefix-cls}-step {
      width: 4px;
      height: 100%;
    }

    .@{slider-prefix-cls}-dot {
      top: auto;
      left: 2px;
      margin-bottom: -4px;
    }
  }
}
