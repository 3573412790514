@import '../../style/themes/default';
@import '../../style/mixins/index';

@list-prefix-cls: ~'@{ant-prefix}-list';

.@{list-prefix-cls} {
  .reset-component;
  position: relative;

  * {
    outline: none;
  }

  &-pagination {
    margin-top: 24px;
    text-align: right;
  }

  &-more {
    margin-top: 12px;
    text-align: center;
    button {
      padding-right: 32px;
      padding-left: 32px;
    }
  }

  &-spin {
    min-height: 40px;
    text-align: center;
  }

  &-empty-text {
    padding: @list-empty-text-padding;
    color: @disabled-color;
    font-size: @font-size-base;
    text-align: center;
  }

  &-item {
    display: flex;
    align-items: center;
    padding: @list-item-padding;

    &-no-flex {
      display: block;
    }

    &-content {
      color: @text-color;
    }

    &-meta {
      display: flex;
      flex: 1;
      align-items: flex-start;
      font-size: 0;
      &-avatar {
        margin-right: @list-item-meta-avatar-margin-right;
      }
      &-content {
        flex: 1 0;
      }
      &-title {
        margin-bottom: 4px;
        color: @text-color;
        font-size: @font-size-base;
        line-height: 22px;
        > a {
          color: @text-color;
          transition: all 0.3s;
          &:hover {
            color: @primary-color;
          }
        }
      }
      &-description {
        color: @text-color-secondary;
        font-size: @font-size-base;
        line-height: 22px;
      }
    }
    &-action {
      flex: 0 0 auto;
      margin-left: 48px;
      padding: 0;
      font-size: 0;
      list-style: none;
      & > li {
        position: relative;
        display: inline-block;
        padding: 0 8px;
        color: @text-color-secondary;
        font-size: @font-size-base;
        line-height: 22px;
        text-align: center;
        cursor: pointer;
      }
      & > li:first-child {
        padding-left: 0;
      }
      &-split {
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 14px;
        margin-top: -7px;
        background-color: @border-color-split;
      }
    }
  }

  &-header {
    background: @list-header-background;
  }

  &-footer {
    background: @list-footer-background;
  }

  &-header,
  &-footer {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &-empty {
    padding: 16px 0;
    color: @text-color-secondary;
    font-size: 12px;
    text-align: center;
  }

  &-split &-item {
    border-bottom: 1px solid @border-color-split;
    &:last-child {
      border-bottom: none;
    }
  }

  &-split &-header {
    border-bottom: 1px solid @border-color-split;
  }

  &-loading &-spin-nested-loading {
    min-height: 32px;
  }

  &-something-after-last-item .@{ant-prefix}-spin-container > &-item:last-child {
    border-bottom: 1px solid @border-color-split;
  }

  &-lg &-item {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  &-sm &-item {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &-vertical &-item {
    align-items: initial;

    &-main {
      display: block;
      flex: 1;
    }

    &-extra {
      margin-left: 40px;
    }

    &-meta {
      margin-bottom: @list-item-meta-margin-bottom;

      &-title {
        margin-bottom: @list-item-meta-title-margin-bottom;
        color: @heading-color;
        font-size: @font-size-lg;
        line-height: 24px;
      }
    }

    &-action {
      margin-top: @padding-md;
      margin-left: auto;

      > li {
        padding: 0 16px;
        &:first-child {
          padding-left: 0;
        }
      }
    }
  }

  &-grid &-item {
    display: block;
    max-width: 100%;
    margin-bottom: 16px;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

@import './bordered';
@import './responsive';
