@import '../../style/themes/default';
@import '../../style/mixins/index';

@tooltip-prefix-cls: ~'@{ant-prefix}-tooltip';

// Base class
.@{tooltip-prefix-cls} {
  .reset-component;
  position: absolute;
  z-index: @zindex-tooltip;
  display: block;
  max-width: @tooltip-max-width;
  visibility: visible;

  &-hidden {
    display: none;
  }

  &-placement-top,
  &-placement-topLeft,
  &-placement-topRight {
    padding-bottom: @tooltip-distance;
  }
  &-placement-right,
  &-placement-rightTop,
  &-placement-rightBottom {
    padding-left: @tooltip-distance;
  }
  &-placement-bottom,
  &-placement-bottomLeft,
  &-placement-bottomRight {
    padding-top: @tooltip-distance;
  }
  &-placement-left,
  &-placement-leftTop,
  &-placement-leftBottom {
    padding-right: @tooltip-distance;
  }

  // Wrapper for the tooltip content
  &-inner {
    min-width: 30px;
    min-height: 32px;
    padding: 6px 8px;
    color: @tooltip-color;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background-color: @tooltip-bg;
    border-radius: @border-radius-base;
    box-shadow: @box-shadow-base;
  }

  // Arrows
  &-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
  }

  &-placement-top &-arrow,
  &-placement-topLeft &-arrow,
  &-placement-topRight &-arrow {
    bottom: @tooltip-distance - @tooltip-arrow-width;
    border-width: @tooltip-arrow-width @tooltip-arrow-width 0;
    border-top-color: @tooltip-arrow-color;
  }

  &-placement-top &-arrow {
    left: 50%;
    margin-left: -@tooltip-arrow-width;
  }

  &-placement-topLeft &-arrow {
    left: 16px;
  }

  &-placement-topRight &-arrow {
    right: 16px;
  }

  &-placement-right &-arrow,
  &-placement-rightTop &-arrow,
  &-placement-rightBottom &-arrow {
    left: @tooltip-distance - @tooltip-arrow-width;
    border-width: @tooltip-arrow-width @tooltip-arrow-width @tooltip-arrow-width 0;
    border-right-color: @tooltip-arrow-color;
  }

  &-placement-right &-arrow {
    top: 50%;
    margin-top: -@tooltip-arrow-width;
  }

  &-placement-rightTop &-arrow {
    top: 8px;
  }

  &-placement-rightBottom &-arrow {
    bottom: 8px;
  }

  &-placement-left &-arrow,
  &-placement-leftTop &-arrow,
  &-placement-leftBottom &-arrow {
    right: @tooltip-distance - @tooltip-arrow-width;
    border-width: @tooltip-arrow-width 0 @tooltip-arrow-width @tooltip-arrow-width;
    border-left-color: @tooltip-arrow-color;
  }

  &-placement-left &-arrow {
    top: 50%;
    margin-top: -@tooltip-arrow-width;
  }

  &-placement-leftTop &-arrow {
    top: 8px;
  }

  &-placement-leftBottom &-arrow {
    bottom: 8px;
  }

  &-placement-bottom &-arrow,
  &-placement-bottomLeft &-arrow,
  &-placement-bottomRight &-arrow {
    top: @tooltip-distance - @tooltip-arrow-width;
    border-width: 0 @tooltip-arrow-width @tooltip-arrow-width;
    border-bottom-color: @tooltip-arrow-color;
  }

  &-placement-bottom &-arrow {
    left: 50%;
    margin-left: -@tooltip-arrow-width;
  }

  &-placement-bottomLeft &-arrow {
    left: 16px;
  }

  &-placement-bottomRight &-arrow {
    right: 16px;
  }
}
